// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Z_hYkwm5M", "jLV5sVP1n"];

const serializationHash = "framer-vl2dq"

const variantClassNames = {jLV5sVP1n: "framer-v-tnskot", Z_hYkwm5M: "framer-v-1y0dc5x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "Z_hYkwm5M", "Variant 2": "jLV5sVP1n"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, KUG1wso0R: image ?? props.KUG1wso0R ?? {src: "https://framerusercontent.com/images/nLqmVzObtSOI9t27fA3eIR81w.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/nLqmVzObtSOI9t27fA3eIR81w.png?scale-down-to=512 512w, https://framerusercontent.com/images/nLqmVzObtSOI9t27fA3eIR81w.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/nLqmVzObtSOI9t27fA3eIR81w.png?scale-down-to=2048 2048w, https://framerusercontent.com/images/nLqmVzObtSOI9t27fA3eIR81w.png 3752w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Z_hYkwm5M"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KUG1wso0R, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Z_hYkwm5M", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapi3dkwd = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
await delay(() => setVariant("jLV5sVP1n"), 200)
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", intrinsicHeight: 2160, intrinsicWidth: 3752, pixelHeight: 2160, pixelWidth: 3752, sizes: "min(578px, 100vw)", ...toResponsiveImage(KUG1wso0R)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y0dc5x", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"Z_hYkwm5M"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapi3dkwd} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}} {...addPropertyOverrides({jLV5sVP1n: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1nnuajb"} layoutDependency={layoutDependency} layoutId={"VsmuoyOG0"} style={{backgroundColor: "rgb(153, 238, 255)"}}/></Image></Transition>
</LayoutGroup>)

});

const css = [".framer-vl2dq[data-border=\"true\"]::after, .framer-vl2dq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vl2dq.framer-1fvpq30, .framer-vl2dq .framer-1fvpq30 { display: block; }", ".framer-vl2dq.framer-1y0dc5x { cursor: pointer; height: 502px; overflow: visible; position: relative; width: 578px; }", ".framer-vl2dq .framer-1nnuajb { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-vl2dq.framer-v-tnskot.framer-1y0dc5x { aspect-ratio: 1.15 / 1; height: var(--framer-aspect-ratio-supported, 503px); }", ".framer-vl2dq.framer-v-tnskot .framer-1nnuajb { bottom: unset; height: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 503
 * @framerIntrinsicWidth 578
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jLV5sVP1n":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KUG1wso0R":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerfQ51cw76A: React.ComponentType<Props> = withCSS(Component, css, "framer-vl2dq") as typeof Component;
export default FramerfQ51cw76A;

FramerfQ51cw76A.displayName = "Hero Image";

FramerfQ51cw76A.defaultProps = {height: 503, width: 578};

addPropertyControls(FramerfQ51cw76A, {variant: {options: ["Z_hYkwm5M", "jLV5sVP1n"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, KUG1wso0R: {__defaultAssetReference: "data:framer/asset-reference,nLqmVzObtSOI9t27fA3eIR81w.png?originalFilename=iPhone+05.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerfQ51cw76A, [])